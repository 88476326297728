//@import url('https://fonts.googleapis.com/css2?family=Newsreader:wght@400;700&family=Open+Sans&display=swap')

//@import url('/fonts/Newsreader/Roboto-Regular.woff2');

@import url('/src/assets/fonts/Newsreader/Newsreader-Bold.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-Italic.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-Light.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-Medium.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-Regular.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf')
@import url('/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf')

@import url('/src/assets/fonts/Catamaran/Catamaran-Black.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-Bold.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-Light.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-Medium.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-Regular.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf')
@import url('/src/assets/fonts/Catamaran/Catamaran-Thin.ttf')

$heading-font-family : 'Newsreader', serif


